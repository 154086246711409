import React from "react"
import PropTypes from "prop-types"
import ContentfulComponents from "../helpers/ContentfulComponents"
import Layout from "../components/Layout/Layout"
import GlobalContext from "../context/GlobalContext"
import { setDataLayer, trackPageView } from "../helpers/dataLayerSetter"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"
import {convertToWebp} from "../helpers/ImageHelper";

const Page = ({ path, pageContext }) => {
    const { globalComponents, componentProps, seoData, pageType, bottomBackgroundImage, dataLayer, globalLabels } =
        pageContext

    setDataLayer(dataLayer, seoData, pageType)
    trackPageView(path)

    const { imageDesktop, imageMobile } = bottomBackgroundImage || {}

    return (
        <GlobalContext labels={globalLabels}>
            <Layout seoData={seoData} globalComponents={globalComponents}>
                <div className="relative pb-40">
                    <ContentfulComponents componentsProps={componentProps} pageType={pageType} />

                    {bottomBackgroundImage && (
                        <picture>
                            {imageDesktop && (
                                <>
                                    <source width={imageDesktop?.width || 'auto'}
                                            height={imageDesktop?.height || 'auto'}
                                            srcSet={convertToWebp(imageDesktop.url)} type="image/webp"
                                            media="(min-width: 992px)"/>
                                    <source width={imageDesktop?.width || 'auto'}
                                            height={imageDesktop?.height || 'auto'} srcSet={`${imageDesktop.url}?q=80`}
                                            type="image/png"
                                            media="(min-width: 992px)"/>
                                </>
                            )}
                            <source width={imageMobile?.width || imageDesktop?.width || 'auto'}
                                    height={imageMobile?.height || imageDesktop?.height || 'auto'}
                                    srcSet={convertToWebp(imageMobile?.url || imageDesktop?.url)} type="image/webp"/>
                            <source width={imageMobile?.width || imageDesktop?.width || 'auto'}
                                    height={imageMobile?.height || imageDesktop?.height || 'auto'}
                                    srcSet={`${imageMobile?.url || imageDesktop?.url}?q=80`} type="image/png"/>
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII"
                                alt={bottomBackgroundImage?.altText}
                                className="absolute bottom-0 left-0 right-0 w-full z-[-1] max-h-[1700px] lg:max-h-[950px]"
                            />
                        </picture>
                    )}
                </div>
            </Layout>
        </GlobalContext>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />

        </>
    )
}

Page.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        bottomBackgroundImage: PropTypes.object,
        seoData: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            ogTitle: PropTypes.string,
            ogDescription: PropTypes.string,
            ogType: PropTypes.string,
            twitterTitle: PropTypes.string,
            twitterDescription: PropTypes.string,
            canonicalLink: PropTypes.string,
            metaTitle: PropTypes.string,
            featuredImage: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
        globalLabels: PropTypes.object,
    }),
}

export default Page
